
.nav-logo {
  width: 60px;
}


.section {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 70vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}
.credits {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 30vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.section-dark {
  background: #333;
  color: white;
}

.section-content {
  margin: 0 auto;
  max-width: 1100px;
}
.navigation a{
  font-family: 'Cutive Mono', monospace;
}
h1,h2, p{
  font-family: 'Roboto Slab', serif;
}
.credits p{
  font-family: 'Cutive Mono', monospace;
}
.aboutmepic img{
max-width: 80%;
max-height: 80%;
}

div.text-center.col-md-6.col-12.order-md-2.order-1{
  margin-bottom: 20px;
}


@media (min-width:801px) {
nav.navbar.navbar-expand-lg.navbar-light.bg-white{
  padding-right: 250px;
  padding-left: 250px;
}
}
